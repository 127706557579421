import { useCallback, useMemo, useEffect } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import {
  CategoryID,
  FetchCategoriesScopes
} from '../../../../../../categories/categoriesTypes';
import {
  FetchMaterialCategoriesFilters,
  MaterialClientID,
  ChangeMaterialsFiltersFunc
} from '../../../../../materialsTypes';
import { MaterialBrandsDataItem } from '../../MaterialsBrandsFilter.types';

import {
  FETCH_MATERIAL_BRANDS_SELECTED_QUERY,
  FetchMaterialBrandsSelectedQueryResponse
} from '../../../../../queries/fetchMaterialBrandsSelected.query';

import { useFinPaginatedCategories } from '../../../../../../categories/hooks/useFinPaginatedCategories';
import { useMaterialBrands } from '../../../../../hooks/useMaterialBrands';

import { getMaterialClientIdsCacheKeyPart } from '../../../../../utils/getMaterialClientIdsCacheKeyPart';

import { MaterialCache } from '../../../../../MaterialCache';

const defaultInitialFilters = {
  scope: [FetchCategoriesScopes.MATERIAL_BRANDS]
};

interface useMaterialsBrandsFilterProps {
  clientIds?: MaterialClientID[];
  selectedIds: CategoryID[];
  name: string;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

const defaultFilterKeys: Array<keyof FetchMaterialCategoriesFilters> = [
  'scope',
  'materialClientIds'
];

const staleTime = 1000 * 60 * 60;

function useMaterialsBrandsFilter({
  clientIds,
  selectedIds = [],
  name,
  changeMaterialsFilters
}: useMaterialsBrandsFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? MaterialCache.brandsFilterCacheKey()
    : MaterialCache.brandsLibraryFilterCacheKey(
        getMaterialClientIdsCacheKeyPart({ clientIds })
      );

  const defaultFilters: FetchMaterialCategoriesFilters = merge(
    {},
    defaultInitialFilters,
    isEmpty(clientIds) ? null : { materialClientIds: clientIds }
  );

  const {
    materialBrands,
    materialBrandsErrorMessage,
    materialBrandsFetched,
    materialBrandsIsPlaceholderData,
    materialBrandsFetchingNextPage,
    materialBrandsFilters,
    hasNextMaterialBrandsPage,
    materialBrandsFilterSearchValue,
    loadMoreMaterialBrands,
    filterMaterialBrands,
    changeMaterialBrandsFilters
  } = useMaterialBrands({
    cacheKey,
    initialFilters: defaultFilters
  });

  useEffect(() => {
    if (
      !isEqual(defaultFilters, pick(materialBrandsFilters, defaultFilterKeys))
    ) {
      filterMaterialBrands({
        ...defaultFilters,
        ...pickBy(
          materialBrandsFilters,
          (value, key) => !includes(defaultFilterKeys, key)
        )
      });
    }
  }, [materialBrandsFilters, defaultFilters, filterMaterialBrands]);

  const {
    categories: materialBrandsSelected,
    categoriesError: materialsBrandsSelectedErrorMessage,
    changeCategoriesFilters: changeMaterialBrandsSelectedFilters,
    categoriesFilters: materialBrandsSelectedFilters
  } = useFinPaginatedCategories<FetchMaterialBrandsSelectedQueryResponse>({
    cacheKey: MaterialCache.brandsSelectedFilterCacheKey(),
    query: FETCH_MATERIAL_BRANDS_SELECTED_QUERY,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.MATERIAL_BRANDS },
      id: { in: selectedIds }
    },
    options: {
      keepPreviousData: true,
      staleTime
    }
  });

  useEffect(() => {
    if (!isEqual(materialBrandsSelectedFilters.id?.in, selectedIds)) {
      changeMaterialBrandsSelectedFilters({
        id: { in: selectedIds }
      });
    }
  }, [
    changeMaterialBrandsSelectedFilters,
    materialBrandsSelectedFilters.id?.in,
    selectedIds
  ]);

  const handleMaterialBrandsFilterSearch = useCallback(
    (input: string) => {
      changeMaterialBrandsFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeMaterialBrandsFilters]
  );

  const materialBrandsFilterSelectedData = useMemo(() => {
    const selectedFetchedData = materialBrandsSelected.map((category) => ({
      id: category.id as string,
      label: category.name
    }));

    const selectedData = compact(
      selectedIds.map((id) => find(selectedFetchedData, ['id', id]))
    );
    return selectedData;
  }, [materialBrandsSelected, selectedIds]);

  const materialsBrandsFilterData = useMemo<MaterialBrandsDataItem[]>(() => {
    return materialBrands.map((category) => ({
      id: category.id as string,
      label: category.name
    }));
  }, [materialBrands]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeMaterialsFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeMaterialsFilters, name]
  );

  return {
    materialBrandsFilterData: materialBrands,
    materialBrandsFilterSelectedData,
    materialBrandsFilter: materialBrands,
    materialsBrandsFilterData,
    materialBrandsFilterErrorMessage: materialBrandsErrorMessage,
    materialsBrandsFilterSelectedErrorMessage:
      materialsBrandsSelectedErrorMessage,
    materialBrandsFilterFetched: materialBrandsFetched,
    materialBrandsFilterIsPlaceholderData: materialBrandsIsPlaceholderData,
    materialBrandsFilterFetchingNextPage: materialBrandsFetchingNextPage,
    hasNextMaterialBrandsFilterPage: hasNextMaterialBrandsPage,
    materialBrandsFilterSearchValue,
    handleMaterialBrandsFilterSearch,
    loadMoreMaterialBrandsFilter: loadMoreMaterialBrands,
    handleChangeMaterialsBrandsFilter: handleChange
  };
}

export default useMaterialsBrandsFilter;
