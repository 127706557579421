import { gql } from 'graphql-request';
import {
  MaterialBrandName,
  MaterialBrandUUID,
  MaterialBrandID,
  MaterialCreatedAt,
  MaterialBlocked,
  MaterialFavorite,
  MaterialID,
  MaterialName,
  MaterialNda,
  MaterialUUID,
  MaterialImageUUID,
  MaterialImageFile,
  MaterialNanoID,
  MaterialCategoryID,
  MaterialCategoryName
} from '../materialsTypes';

export interface FetchMaterialsQueryResponse {
  blocked: MaterialBlocked;
  favorite: MaterialFavorite;
  id: MaterialID;
  uuid: MaterialUUID;
  nanoId: MaterialNanoID;
  name: MaterialName;
  nda: MaterialNda;
  sku: MaterialUUID;
  createdAt: MaterialCreatedAt;
  updatedAt: MaterialCreatedAt;
  image: {
    uuid: MaterialImageUUID;
    file: MaterialImageFile;
  };
  brand: {
    id: MaterialBrandID;
    uuid: MaterialBrandUUID;
    name: MaterialBrandName;
  };
  categories?: {
    id: MaterialCategoryID;
    localizedName: MaterialCategoryName;
  }[];
}

export const FETCH_MATERIALS_QUERY = gql`
  query Materials(
    $filters: MaterialsFilters
    $sort: [MaterialsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    materials(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        blocked
        favorite
        id
        uuid
        nanoId
        name
        nda
        sku
        createdAt
        updatedAt
        image {
          uuid
          file
        }
        brand {
          id
          uuid
          name
        }
        categories {
          id
          localizedName
        }
      }
      paginationInfo {
        currentPage
        nextPage
        totalCount
      }
    }
  }
`;
