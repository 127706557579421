import React, { memo, Fragment, useMemo, useCallback } from 'react';
import filter from 'lodash/filter';

import { IsFetched, ErrorMessage } from '../../../../../types';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKeys
} from '../../../../materialsSets/materialsSetsTypes';

import {
  FetchMaterialsCacheKeys,
  UpdateMaterialCacheAction,
  OnSelectedMaterialsSidebarOpenAction,
  OnSelectedMaterialsSidebarCloseAction,
  MaterialNanoID,
  FetchMaterialsFilters,
  ChangeMaterialsFiltersFunc
} from '../../../materialsTypes';
import {
  MaterialsListMaterials,
  MaterialsListMaterial,
  MaterialsListMaterialImages,
  MaterialsListMaterialsSet,
  MaterialsListOnMaterialsSelect
} from './MaterialsList.types';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { MaterialsListItem } from '../MaterialsListItem';

import { MaterialsListItemLightboxBottomButtons } from './components/MaterialsListItemLightboxBottomButtons';
import { MaterialsListItemLightboxTitle } from './components/MaterialsListItemLightboxTitle';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Loading } from '../../../../../helpers/Loading';
import { NoResults } from '../../../../../helpers/NoResults';
import {
  LightboxToggleBackdrop,
  LightboxWrapper,
  useLightboxWrapper,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle
} from '../../../../../helpers/LightboxWrapper';
import { LoadingGridSkeleton } from '../../../../../helpers/LoadingGridSkeleton';

import { Files } from '../../../../../utils/Files';

interface MaterialsListDefaultProps {
  companyNanoId?: TeamNanoID;
  materials: MaterialsListMaterials;
  materialsFetched: IsFetched;
  materialsError: ErrorMessage;
  materialsIsPlaceholderData: boolean;
  materialsTotalCount: number;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsFilters?: FetchMaterialsFilters;
  changeMaterialsFilters?: ChangeMaterialsFiltersFunc;
  updateMaterialCache?: UpdateMaterialCacheAction<MaterialsListMaterial>;
  togglePreventModalClose?: LightboxToggleBackdrop;
  onMaterialEditButtonMouseEnter?: (materialNanoId: MaterialNanoID) => void;
  loadingGridSkeletonItemsCount?: number;
  withMaterialPreviewLink?: boolean;
}

interface MaterialsListWithSelectProps {
  materialsSetFetched: IsFetched;
  materialsSetError: ErrorMessage;
  materialsSetIsPlaceholderData: boolean;
  materialsSet: MaterialsListMaterialsSet | null;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onMaterialsSelect?: MaterialsListOnMaterialsSelect;
  onSelectedMaterialsSidebarOpen: OnSelectedMaterialsSidebarOpenAction;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<MaterialsListMaterialsSet>;
}

interface MaterialsListWithoutSelectProps {
  materialsSetFetched?: never;
  materialsSetError?: never;
  materialsSetIsPlaceholderData?: never;
  materialsSet?: never;
  materialsSetCacheKeys?: never;
  onMaterialsSelect?: never;
  onSelectedMaterialsSidebarOpen?: never;
  onSelectedMaterialsSidebarClose?: never;
  updateMaterialsSetCache?: never;
}

type MaterialsListProps = MaterialsListDefaultProps &
  (MaterialsListWithSelectProps | MaterialsListWithoutSelectProps);

function MaterialsList({
  companyNanoId,
  materials,
  materialsFetched,
  materialsSetFetched,
  materialsError,
  materialsSetError,
  materialsIsPlaceholderData,
  materialsSetIsPlaceholderData,
  materialsTotalCount,
  materialsSet,
  materialsCacheKeys,
  materialsSetCacheKeys,
  materialsFilters,
  changeMaterialsFilters,
  onMaterialsSelect,
  onSelectedMaterialsSidebarOpen,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache,
  updateMaterialCache,
  togglePreventModalClose,
  onMaterialEditButtonMouseEnter,
  loadingGridSkeletonItemsCount,
  withMaterialPreviewLink
}: MaterialsListProps) {
  const materialsInLightbox = useMemo<MaterialsListMaterials>(() => {
    return filter<MaterialsListMaterial>(
      materials,
      (material) => material.image?.file && Files.isImage(material.image.file)
    );
  }, [materials]);

  const lightboxItems = useMemo<MaterialsListMaterialImages>(() => {
    return materialsInLightbox.map((material) => material.image);
  }, [materialsInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      const material = materialsInLightbox[index];
      if (!material) {
        return [];
      }

      return (
        <MaterialsListItemLightboxBottomButtons
          material={material}
          materialsSet={materialsSet}
          materialsCacheKeys={materialsCacheKeys}
          materialsSetCacheKeys={materialsSetCacheKeys}
          onSelectedMaterialsSidebarOpen={onSelectedMaterialsSidebarOpen}
          onSelectedMaterialsSidebarClose={onSelectedMaterialsSidebarClose}
          updateMaterialsSetCache={updateMaterialsSetCache}
          updateMaterialCache={updateMaterialCache}
        />
      );
    },
    [
      materialsInLightbox,
      materialsSet,
      materialsCacheKeys,
      materialsSetCacheKeys,
      onSelectedMaterialsSidebarOpen,
      onSelectedMaterialsSidebarClose,
      updateMaterialsSetCache,
      updateMaterialCache
    ]
  );

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(
    ({ index }) => {
      const material = materialsInLightbox[index];
      if (!material) {
        return null;
      }

      return <MaterialsListItemLightboxTitle material={material} />;
    },
    [materialsInLightbox]
  );

  return (
    <Fragment>
      <AlertMessage
        addClassName="m-4"
        message={materialsError || materialsSetError}
      />
      <Loading
        loaded={!materialsIsPlaceholderData && !materialsSetIsPlaceholderData}
      />
      <LoadingGridSkeleton
        loaded={
          onSelectedMaterialsSidebarOpen
            ? (materialsIsPlaceholderData || materialsFetched) &&
              (materialsSetIsPlaceholderData || materialsSetFetched)
            : materialsIsPlaceholderData || materialsFetched
        }
        itemsCount={loadingGridSkeletonItemsCount}
      >
        {materialsTotalCount === 0 ? (
          <NoResults addErrorClassName="m-4" />
        ) : (
          <div className="px-4 grid grid-cols-auto-fill grid-cell-min-48 xl:grid-cell-min-52 2xl:grid-cell-min-64 3xl:grid-cell-min-72 gap-4 xl:gap-8 2xl:gap-12">
            {materials.map((material) => (
              <MaterialsListItem
                companyNanoId={companyNanoId}
                key={material.uuid}
                material={material}
                materialsSet={materialsSet}
                materialsCacheKeys={materialsCacheKeys}
                materialsSetCacheKeys={materialsSetCacheKeys}
                materialsFilters={materialsFilters}
                changeMaterialsFilters={changeMaterialsFilters}
                onLightboxOpen={handleLightboxOpenOnSlide}
                onSelectedMaterialsSidebarOpen={onSelectedMaterialsSidebarOpen}
                onSelectedMaterialsSidebarClose={
                  onSelectedMaterialsSidebarClose
                }
                updateMaterialsSetCache={updateMaterialsSetCache}
                updateMaterialCache={updateMaterialCache}
                onMaterialsSelect={onMaterialsSelect}
                onMaterialEditButtonMouseEnter={onMaterialEditButtonMouseEnter}
                withMaterialPreviewLink={withMaterialPreviewLink}
              />
            ))}
          </div>
        )}
      </LoadingGridSkeleton>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        renderImageTitle={renderImageTitle}
        renderCustomButtons={renderLightboxButtons}
        withFullScreenButton
      />
    </Fragment>
  );
}

export default memo<MaterialsListProps>(MaterialsList);
