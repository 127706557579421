import React, { useCallback } from 'react';

import {
  MaterialsFiltersNavBasePath,
  ChangeMaterialsFiltersFunc
} from '../../../../../materialsTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { MaterialsIndexPageCategoriesNavCategory } from '../../MaterialsIndexPageCategoriesNav.types';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { getChangeMaterialsFiltersCategoriesData } from '../../../../../utils/getChangeMaterialsFiltersCategoriesData';

import { MaterialPath } from '../../../../../MaterialPath';

interface MaterialsIndexPageCategoriesNavItemProps {
  categories: MaterialsIndexPageCategoriesNavCategory[];
  category: MaterialsIndexPageCategoriesNavCategory;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  withChevron?: boolean;
}

function MaterialsIndexPageCategoriesNavItem({
  categories,
  category,
  changeMaterialsFilters,
  filtersNavBasePath,
  withChevron
}: MaterialsIndexPageCategoriesNavItemProps) {
  const handleLinkClick = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (!filtersNavBasePath) {
        e.preventDefault();
        const { changeFilters, resetFilters } =
          getChangeMaterialsFiltersCategoriesData({
            materialCategory: category,
            categories
          });

        changeMaterialsFilters(changeFilters, resetFilters);
      }
    },
    [filtersNavBasePath, category, categories, changeMaterialsFilters]
  );

  return (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextLinkHelper
          className="focus:outline-none flex-1 px-2 text-sm"
          href={
            filtersNavBasePath
              ? MaterialPath.indexFiltersCustomBaseQuery(
                  filtersNavBasePath,
                  getChangeMaterialsFiltersCategoriesData({
                    materialCategory: category,
                    categories
                  }).changeFilters
                )
              : '#'
          }
          onClick={handleLinkClick}
        >
          {category.localizedName}
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>

        {withChevron && (
          <Icon
            icon={IconsEnum.CHEVRON_RIGHT}
            className="h-5 w-5 flex-shrink-0"
          />
        )}
      </div>
    </div>
  );
}

export default MaterialsIndexPageCategoriesNavItem;
