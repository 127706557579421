import React, { useCallback } from 'react';

import { MaterialsListItemPreviewMaterial } from './MaterialsListItemPreview.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../../../helpers/ImageHelper';

import { Files } from '../../../../../../../utils/Files';

import { MaterialPath } from '../../../../../MaterialPath';

const srcSetVersions = {
  '1x': ImageItemImageVersions.MiniThumb320x320,
  '2x': ImageItemImageVersions.MiniThumb640x640,
  '3x': ImageItemImageVersions.MiniThumb960x960
};

interface MaterialsListItemPreviewProps {
  material: MaterialsListItemPreviewMaterial;
  materialPagePath?: string;
  onLightboxOpen?: () => void;
}

function MaterialsListItemPreview({
  material,
  materialPagePath,
  onLightboxOpen
}: MaterialsListItemPreviewProps) {
  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      e.preventDefault();
      onLightboxOpen?.();
    },
    [onLightboxOpen]
  );

  return (
    <div className="relative rounded-md overflow-hidden aspect-w-1 aspect-h-1">
      {material.image?.file && Files.isImage(material.image?.file) ? (
        <NextLinkHelper
          className="absolute inset-0"
          href={materialPagePath || MaterialPath.show(material.nanoId)}
          onClick={onLightboxOpen ? handleOpenLightbox : undefined}
        >
          <ImageHelper
            className="absolute inset-0 object-cover"
            src={material.image.file}
            version={ImageItemImageVersions.MiniThumb320x320}
            srcSetVersions={srcSetVersions}
            alt={material.name}
          />
        </NextLinkHelper>
      ) : (
        <div className="flex items-center justify-center text-gray-600 absolute inset-0">
          <Icon icon={IconsEnum.PHOTOGRAPH_OUTLINE} className="h-12 w-12" />
        </div>
      )}
    </div>
  );
}

export default MaterialsListItemPreview;
