import React, { Fragment } from 'react';

import {
  FetchMaterialsCacheKeys,
  OnSelectedMaterialsSidebarCloseAction,
  OnSelectedMaterialsSidebarOpenAction,
  UpdateMaterialCacheAction
} from '../../../../../materialsTypes';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKeys
} from '../../../../../../materialsSets/materialsSetsTypes';

import { MaterialsListItemLightboxDownloadButton } from '../MaterialsListItemLightboxDownloadButton';
import { MaterialsListItemLightboxFavoriteButton } from '../MaterialsListItemLightboxFavoriteButton';
import { MaterialsListItemLightboxSelectButton } from '../MaterialsListItemLightboxSelectButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  MaterialsListItemLightboxBottomButtonsMaterial,
  MaterialsListItemLightboxBottomButtonsMaterialsSet
} from './MaterialsListItemLightboxBottomButtons.types';

import { MaterialsPermissions } from '../../../../../materialsConstants';

interface MaterialsListItemLightboxBottomButtonsDefaultProps {
  material: MaterialsListItemLightboxBottomButtonsMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  updateMaterialCache?: UpdateMaterialCacheAction<MaterialsListItemLightboxBottomButtonsMaterial>;
}

interface MaterialsListItemLightboxBottomButtonsWithSelectProps {
  materialsSet: MaterialsListItemLightboxBottomButtonsMaterialsSet | null;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onSelectedMaterialsSidebarOpen: OnSelectedMaterialsSidebarOpenAction;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<MaterialsListItemLightboxBottomButtonsMaterialsSet>;
}

interface MaterialsListItemLightboxBottomButtonsWithoutProps {
  materialsSet?: never;
  materialsSetCacheKeys?: never;
  onSelectedMaterialsSidebarOpen?: never;
  onSelectedMaterialsSidebarClose?: never;
  updateMaterialsSetCache?: never;
}

type MaterialsListItemLightboxBottomButtonsProps =
  MaterialsListItemLightboxBottomButtonsDefaultProps &
    (
      | MaterialsListItemLightboxBottomButtonsWithSelectProps
      | MaterialsListItemLightboxBottomButtonsWithoutProps
    );

function MaterialsListItemLightboxBottomButtons({
  material,
  materialsSet,
  materialsCacheKeys,
  materialsSetCacheKeys,
  onSelectedMaterialsSidebarOpen,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache,
  updateMaterialCache
}: MaterialsListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      {onSelectedMaterialsSidebarOpen && (
        <MaterialsListItemLightboxSelectButton
          material={material}
          materialsSet={materialsSet}
          materialsSetCacheKeys={materialsSetCacheKeys}
          onSelectedMaterialsSidebarOpen={onSelectedMaterialsSidebarOpen}
          onSelectedMaterialsSidebarClose={onSelectedMaterialsSidebarClose}
          updateMaterialsSetCache={updateMaterialsSetCache}
        />
      )}
      <MaterialsListItemLightboxFavoriteButton
        material={material}
        materialsCacheKeys={materialsCacheKeys}
        materialsSetCacheKeys={materialsSetCacheKeys}
        updateMaterialCache={updateMaterialCache}
      />
      <CheckPermissions
        action={MaterialsPermissions.READ_MATERIAL_DOWNLOAD_BUTTON}
      >
        <MaterialsListItemLightboxDownloadButton material={material} />
      </CheckPermissions>
    </Fragment>
  );
}

export default MaterialsListItemLightboxBottomButtons;
