import React from 'react';

import {
  FetchMaterialsFilters,
  MaterialsFiltersNavBasePath,
  MaterialsCustomExitNavPath,
  MaterialsI18nCustomBaseTitle,
  ChangeMaterialsFiltersFunc
} from '../../../../../materialsTypes';

import useMaterialsIndexPageCategoriesNavBody from './useMaterialsIndexPageCategoriesNavBody';

import { MaterialsIndexPageCategoriesNavCategory } from '../../MaterialsIndexPageCategoriesNav.types';

import { MaterialsIndexPageCategoriesNavHead } from '../MaterialsIndexPageCategoriesNavHead';
import { MaterialsIndexPageCategoriesNavList } from '../MaterialsIndexPageCategoriesNavList';

import { getChangeMaterialsFiltersCategoriesData } from '../../../../../utils/getChangeMaterialsFiltersCategoriesData';

import { MaterialPath } from '../../../../../MaterialPath';

import { materialsKeys } from '../../../../../../../locales/keys';

interface MaterialsIndexPageCategoriesNavProps {
  materialCategories: MaterialsIndexPageCategoriesNavCategory[];
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  customExitNavPath?: MaterialsCustomExitNavPath;
  i18nCustomBaseTitle?: MaterialsI18nCustomBaseTitle;
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

function MaterialsIndexPageCategoriesNavBody({
  materialCategories,
  filtersNavBasePath,
  i18nCustomBaseTitle,
  customExitNavPath,
  materialsFilters,
  changeMaterialsFilters
}: MaterialsIndexPageCategoriesNavProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useMaterialsIndexPageCategoriesNavBody({
    materialCategories,
    materialsFilters,
    changeMaterialsFilters
  });

  if (currentParentCategory && currentChildCategory) {
    return (
      <>
        <MaterialsIndexPageCategoriesNavHead
          text={currentChildCategory.localizedName}
          backHref={
            filtersNavBasePath
              ? MaterialPath.indexFiltersCustomBaseQuery(
                  filtersNavBasePath,
                  getChangeMaterialsFiltersCategoriesData({
                    materialCategory: currentParentCategory,
                    categories: materialCategories
                  }).changeFilters
                )
              : '#'
          }
          filtersNavBasePath={filtersNavBasePath}
          onChangeFiltersBack={handleParentCategoryChangeFilters}
        />
        <MaterialsIndexPageCategoriesNavList
          materialCategories={materialCategories}
          parentCategoryId={currentChildCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          changeMaterialsFilters={changeMaterialsFilters}
        />
      </>
    );
  }

  if (currentParentCategory) {
    const backHrefWithoutBasePath = !filtersNavBasePath && '#';
    const backHrefWithBasePath = currentParentCategory.parent?.id
      ? MaterialPath.indexFiltersCustomBaseQuery(
          filtersNavBasePath,
          getChangeMaterialsFiltersCategoriesData({
            materialCategory: currentParentCategory.parent,
            categories: materialCategories
          }).changeFilters
        )
      : filtersNavBasePath;

    return (
      <>
        <MaterialsIndexPageCategoriesNavHead
          text={currentParentCategory.localizedName}
          backHref={backHrefWithoutBasePath || backHrefWithBasePath}
          filtersNavBasePath={filtersNavBasePath}
          onChangeFiltersBack={handleResetCategoriesFilters}
        />
        <MaterialsIndexPageCategoriesNavList
          materialCategories={materialCategories}
          parentCategoryId={currentParentCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          changeMaterialsFilters={changeMaterialsFilters}
        />
      </>
    );
  }

  if (currentChildCategory) {
    return (
      <>
        <MaterialsIndexPageCategoriesNavHead
          text={currentChildCategory.localizedName}
          backHref={filtersNavBasePath || '#'}
          filtersNavBasePath={filtersNavBasePath}
          onChangeFiltersBack={handleResetCategoriesFilters}
        />
        <MaterialsIndexPageCategoriesNavList
          materialCategories={materialCategories}
          parentCategoryId={currentChildCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          changeMaterialsFilters={changeMaterialsFilters}
        />
      </>
    );
  }

  return (
    <>
      <MaterialsIndexPageCategoriesNavHead
        i18nText={i18nCustomBaseTitle || materialsKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
        backHref={customExitNavPath}
      />
      <MaterialsIndexPageCategoriesNavList
        materialCategories={materialCategories}
        filtersNavBasePath={filtersNavBasePath}
        changeMaterialsFilters={changeMaterialsFilters}
      />
    </>
  );
}

export default MaterialsIndexPageCategoriesNavBody;
