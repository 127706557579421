import React, { memo, useCallback, useMemo } from 'react';
import some from 'lodash/some';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import {
  FetchMaterialsSetsCacheKeys,
  UpdateMaterialsSetCacheAction
} from '../../../../materialsSets/materialsSetsTypes';

import {
  ChangeMaterialsFiltersFunc,
  FetchMaterialsCacheKeys,
  FetchMaterialsFilters,
  MaterialNanoID,
  OnSelectedMaterialsSidebarCloseAction,
  OnSelectedMaterialsSidebarOpenAction,
  UpdateMaterialCacheAction
} from '../../../materialsTypes';
import {
  MaterialsListItemMaterial,
  MaterialsListItemMaterialsSet,
  MaterialsListItemOnMaterialsSelect
} from './MaterialsListItem.types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { MaterialsListItemBrandNameFilterButton } from './components/MaterialsListItemBrandNameFilterButton';
import { MaterialsCopyLinkButton } from '../../buttons/MaterialsCopyLinkButton';
import { DownloadMaterialButton } from '../../buttons/DownloadMaterialButton';
import { MaterialsListItemEditLink } from './components/MaterialsListItemEditLink';
import { MaterialsListItemNda } from './components/MaterialsListItemNda';
import { MaterialsListItemOpenLightboxButton } from './components/MaterialsListItemOpenLightboxButton';
import { MaterialsListItemPreview } from './components/MaterialsListItemPreview';
import { MaterialSelectButton } from '../../buttons/MaterialSelectButton';
import { MaterialsListItemUpdateImageVersions } from './components/MaterialsListItemUpdateImageVersions';
import { SubmitMaterialToProjectOrTaskModalButton } from './components/SubmitMaterialToProjectOrTaskModalButton';
import { MaterialsListItemSku } from './components/MaterialsListItemSku';
import { MaterialFavoriteButton } from '../../buttons/MaterialFavoriteButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { NextPureTooltipIconLinkHelper } from '../../../../../helpers/links/NextPureTooltipIconLinkHelper';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { MaterialsPermissions } from '../../../materialsConstants';

import { MaterialPath } from '../../../MaterialPath';
import { TeamPath } from '../../../../teams/TeamPath';

import { words } from '../../../../../locales/keys';

interface MaterialsListItemDefaultProps {
  companyNanoId?: TeamNanoID;
  material: MaterialsListItemMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsFilters?: FetchMaterialsFilters;
  changeMaterialsFilters?: ChangeMaterialsFiltersFunc;
  onLightboxOpen: OnLightboxOpenAction;
  updateMaterialCache?: UpdateMaterialCacheAction<MaterialsListItemMaterial>;
  onMaterialEditButtonMouseEnter?: (materialNanoId: MaterialNanoID) => void;
  withMaterialPreviewLink?: boolean;
}

interface MaterialsListItemWithSelectProps {
  materialsSet: MaterialsListItemMaterialsSet | null;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onSelectedMaterialsSidebarOpen: OnSelectedMaterialsSidebarOpenAction;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<MaterialsListItemMaterialsSet>;
  onMaterialsSelect?: MaterialsListItemOnMaterialsSelect;
}

interface MaterialsListItemWithoutSelectProps {
  materialsSet?: never;
  materialsSetCacheKeys?: never;
  onSelectedMaterialsSidebarOpen?: never;
  onSelectedMaterialsSidebarClose?: never;
  updateMaterialsSetCache?: never;
  onMaterialsSelect?: never;
}

type MaterialsListItemProps = MaterialsListItemDefaultProps &
  (MaterialsListItemWithSelectProps | MaterialsListItemWithoutSelectProps);

function MaterialsListItem({
  companyNanoId,
  material,
  materialsSet,
  materialsCacheKeys,
  materialsSetCacheKeys,
  materialsFilters,
  changeMaterialsFilters,
  onLightboxOpen,
  onSelectedMaterialsSidebarOpen,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache,
  updateMaterialCache,
  onMaterialsSelect,
  onMaterialEditButtonMouseEnter,
  withMaterialPreviewLink
}: MaterialsListItemProps) {
  const handleOpenLightbox = useCallback(() => {
    if (material.image) {
      onLightboxOpen(material.image);
    }
  }, [material, onLightboxOpen]);

  const isSelected = useMemo<boolean>(() => {
    if (!materialsSet || !materialsSet.selectedMaterials) {
      return false;
    }
    return some(
      materialsSet.selectedMaterials,
      (selectedMaterial) => selectedMaterial?.material.uuid === material.uuid
    );
  }, [material, materialsSet]);

  const handleEditButtonMouseEnter = useCallback<() => void>(() => {
    onMaterialEditButtonMouseEnter?.(material.nanoId);
  }, [onMaterialEditButtonMouseEnter, material.nanoId]);

  const materialPagePath = companyNanoId
    ? TeamPath.companyLibraryMaterial(companyNanoId, material.nanoId)
    : MaterialPath.show(material.nanoId);

  return (
    <div className="w-80 max-w-full flex flex-col mx-auto relative rounded-md group">
      <MaterialsListItemPreview
        materialPagePath={materialPagePath}
        material={material}
        onLightboxOpen={
          withMaterialPreviewLink ? undefined : handleOpenLightbox
        }
      />
      <div className="text-sm p-3 flex-1 flex flex-col">
        <div className="flex-1">
          <div className="font-medium truncate">
            <NextPureTooltipIconLinkHelper
              href={materialPagePath}
              text={material.name}
              tooltipI18nText={material.name}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          </div>
          <div className="text-gray-600 dark:text-gray-500 truncate">
            <MaterialsListItemNda material={material} />
            <MaterialsListItemBrandNameFilterButton
              material={material}
              materialsFilters={materialsFilters}
              changeMaterialsFilters={changeMaterialsFilters}
            />
          </div>
          <MaterialsListItemSku material={material} />
        </div>

        <div className="mt-3 flex justify-between items-center relative z-5">
          <div className="flex items-center gap-2">
            {onSelectedMaterialsSidebarOpen ? (
              <MaterialSelectButton
                material={material}
                materialsSet={materialsSet}
                materialsSetCacheKeys={materialsSetCacheKeys}
                onSelectedMaterialsSidebarOpen={onSelectedMaterialsSidebarOpen}
                onSelectedMaterialsSidebarClose={
                  onSelectedMaterialsSidebarClose
                }
                updateMaterialsSetCache={updateMaterialsSetCache}
                onSelect={onMaterialsSelect}
                selectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm text-white bg-blue-500 hover:bg-blue-600 border-transparent space-x-1"
                unselectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50"
              />
            ) : (
              <CheckPermissions
                action={MaterialsPermissions.READ_MATERIAL_DOWNLOAD_BUTTON}
              >
                <DownloadMaterialButton
                  materialUuid={material.uuid}
                  className="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50"
                  i18nText={words.download}
                  tooltipSingleton
                />
              </CheckPermissions>
            )}
          </div>

          <CheckPermissions
            action={MaterialsPermissions.READ_MATERIAL_FAVORITE_BUTTON}
          >
            <MaterialFavoriteButton
              material={material}
              materialsCacheKeys={materialsCacheKeys}
              materialsSetCacheKeys={materialsSetCacheKeys}
              updateMaterialCache={updateMaterialCache}
              className={
                material.favorite
                  ? 'focus:ring-offset-0 items-center rounded-full text-pink-600 hover:text-pink-500 flex p-0.5'
                  : 'focus:ring-offset-0 items-center rounded-full text-gray-400 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600 flex p-1'
              }
              icon={
                material.favorite
                  ? IconsEnum.HEART_SOLID
                  : IconsEnum.HEART_OUTLINE
              }
            />
          </CheckPermissions>
        </div>
      </div>
      {isSelected ? (
        <div className="absolute inset-0 rounded-md ring-4 ring-blue-500 bg-blue-500 opacity-10 pointer-events-none" />
      ) : null}
      {material.blocked ? (
        <div className="absolute inset-0 rounded-md bg-crossed bg-red-200 bg-opacity-10 pointer-events-none" />
      ) : null}
      <div className="absolute inset-0 pointer-events-none flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 items-start justify-end">
        <div className="flex flex-col space-y-1 pointer-events-auto p-2">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.LEFT}
            withArrow
          >
            <CheckPermissions
              action={MaterialsPermissions.READ_MATERIAL_DOWNLOAD_BUTTON}
            >
              <DownloadMaterialButton
                materialUuid={material.uuid}
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                icon={IconsEnum.DOWNLOAD_SOLID}
                iconClassName="h-5 w-5"
                tooltipI18nText={words.download}
                tooltipSingleton
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                MaterialsPermissions.READ_MATERIAL_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
              }
            >
              <SubmitMaterialToProjectOrTaskModalButton material={material} />
            </CheckPermissions>

            <MaterialsListItemOpenLightboxButton
              material={material}
              onLightboxOpen={handleOpenLightbox}
            />
            <CheckPermissions
              action={
                MaterialsPermissions.READ_MATERIAL_UPDATE_IMAGE_VERSION_BUTTON
              }
            >
              <MaterialsListItemUpdateImageVersions material={material} />
            </CheckPermissions>
            <CheckPermissions
              action={MaterialsPermissions.READ_MATERIAL_COPY_LINK_BUTTON}
            >
              <MaterialsCopyLinkButton
                material={material}
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                icon={IconsEnum.LINK_SOLID}
                iconClassName="h-5 w-5 stroke-1.75"
                tooltipI18nText={words.copyLink}
              />
            </CheckPermissions>
            <CheckPermissions
              action={MaterialsPermissions.READ_MATERIAL_EDIT_BUTTON}
            >
              <MaterialsListItemEditLink
                material={material}
                onMouseEnter={handleEditButtonMouseEnter}
              />
            </CheckPermissions>
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
    </div>
  );
}

export default memo<MaterialsListItemProps>(MaterialsListItem);
