import { useCallback } from 'react';
import compact from 'lodash/compact';

import {
  FetchMaterialsCacheKeys,
  UpdateMaterialCacheAction
} from '../../../../../materialsTypes';

import { FetchMaterialsSetsCacheKeys } from '../../../../../../materialsSets/materialsSetsTypes';

import {
  TOGGLE_MATERIAL_FAVORITE_QUERY,
  ToggleMaterialFavoriteQueryResponse
} from '../../../../../queries/toggleMaterialFavorite.query';

import { useToggleMaterialFavorite } from '../../../../../hooks/useToggleMaterialFavorite';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { MaterialCache } from '../../../../../MaterialCache';
import { MaterialsSetCache } from '../../../../../../materialsSets/MaterialsSetCache';

import { MaterialsListItemLightboxFavoriteButtonMaterial } from './MaterialsListItemLightboxFavoriteButton.types';

interface useMaterialsListItemLightboxFavoriteButtonProps {
  material: MaterialsListItemLightboxFavoriteButtonMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  updateMaterialCache?: UpdateMaterialCacheAction<MaterialsListItemLightboxFavoriteButtonMaterial>;
}

function useMaterialsListItemLightboxFavoriteButton({
  material,
  materialsCacheKeys,
  materialsSetCacheKeys,
  updateMaterialCache
}: useMaterialsListItemLightboxFavoriteButtonProps) {
  const { toggleMaterialFavorite, toggleMaterialFavoriteErrorMessage } =
    useToggleMaterialFavorite<ToggleMaterialFavoriteQueryResponse>({
      query: TOGGLE_MATERIAL_FAVORITE_QUERY,
      cacheKeys: compact([
        MaterialCache.indexCacheKey(),
        MaterialsSetCache.showCacheKey(),
        ...(materialsCacheKeys || []),
        ...(materialsSetCacheKeys || [])
      ]),
      updateMaterialCache
    });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleMaterialFavorite({ uuid: material.uuid }),
    [material, toggleMaterialFavorite]
  );

  useShowToastOnErrorChange({ error: toggleMaterialFavoriteErrorMessage });

  return {
    handleToggleFavorite
  };
}

export default useMaterialsListItemLightboxFavoriteButton;
