import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {
  FetchMaterialsFilters,
  MaterialsFiltersNavBasePath,
  MaterialsCustomExitNavPath,
  MaterialsI18nCustomBaseTitle,
  ChangeMaterialsFiltersFunc
} from '../../../materialsTypes';

import { FetchMaterialCategoriesQueryResponse } from '../../../queries/fetchMaterialCategories.query';

import { useMaterialCategories } from '../../../hooks/useMaterialCategories';

import { MaterialsIndexPageCategoriesNavBody } from './components/MaterialsIndexPageCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { MaterialCache } from '../../../MaterialCache';

interface MaterialsIndexPageCategoriesNavProps {
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  customExitNavPath?: MaterialsCustomExitNavPath;
  i18nCustomBaseTitle?: MaterialsI18nCustomBaseTitle;
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

function MaterialsIndexPageCategoriesNav({
  filtersNavBasePath,
  customExitNavPath,
  i18nCustomBaseTitle,
  materialsFilters,
  changeMaterialsFilters
}: MaterialsIndexPageCategoriesNavProps) {
  const {
    materialCategories,
    materialCategoriesErrorMessage,
    materialCategoriesFetched,
    materialCategoriesIsPlaceholderData
  } = useMaterialCategories({
    cacheKey: MaterialCache.categoriesCacheKey()
  });

  const materialCategoriesWithParents = useMemo<
    FetchMaterialCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      materialCategories.map((category) => category.parent)
    );

    return sortBy(
      uniqBy([...materialCategories, ...parentCategories], 'id'),
      'name'
    );
  }, [materialCategories]);

  return (
    <div className="p-4">
      <AlertMessage message={materialCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          !materialCategoriesIsPlaceholderData && materialCategoriesFetched
        }
      >
        <MaterialsIndexPageCategoriesNavBody
          materialCategories={materialCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          customExitNavPath={customExitNavPath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          materialsFilters={materialsFilters}
          changeMaterialsFilters={changeMaterialsFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default MaterialsIndexPageCategoriesNav;
