import { useMemo, useCallback } from 'react';
import find from 'lodash/find';
import first from 'lodash/first';

import {
  FetchMaterialsFilters,
  ChangeMaterialsFiltersFunc
} from '../../materialsTypes';

import { useMaterialCategories } from '../../hooks/useMaterialCategories';

import { getChangeMaterialsFiltersCategoriesData } from '../../utils/getChangeMaterialsFiltersCategoriesData';

import { MaterialCache } from '../../MaterialCache';

interface UseMaterialsIndexPageBreadcrumbsProps {
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

function useMaterialsIndexPageBreadcrumbs({
  materialsFilters,
  changeMaterialsFilters
}: UseMaterialsIndexPageBreadcrumbsProps) {
  const cacheKey = MaterialCache.categoriesCacheKey();

  const { materialCategories } = useMaterialCategories({ cacheKey });

  const currentParentCategory = useMemo(() => {
    const currentFiltersParentCategoryId = first(
      materialsFilters?.materialParentCategoryIds
    );

    return (
      find(materialCategories, ['id', currentFiltersParentCategoryId]) || null
    );
  }, [materialCategories, materialsFilters]);

  const currentChildCategory = useMemo(() => {
    const currentFiltersChildCategoryId = first(
      materialsFilters?.materialCategoryIds
    );

    return (
      find(materialCategories, ['id', currentFiltersChildCategoryId]) || null
    );
  }, [materialCategories, materialsFilters]);

  const handleResetCategoriesFilters = useCallback(() => {
    const { changeFilters, resetFilters } =
      getChangeMaterialsFiltersCategoriesData({
        materialCategory: null,
        categories: []
      });

    changeMaterialsFilters(changeFilters, resetFilters);
  }, [changeMaterialsFilters]);

  const handleParentCategoryChangeFilters = useCallback(() => {
    const { changeFilters, resetFilters } =
      getChangeMaterialsFiltersCategoriesData({
        materialCategory: currentParentCategory,
        categories: materialCategories
      });

    changeMaterialsFilters(changeFilters, resetFilters);
  }, [changeMaterialsFilters, currentParentCategory, materialCategories]);

  return {
    currentParentCategory,
    currentChildCategory,
    materialCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  };
}

export default useMaterialsIndexPageBreadcrumbs;
