import { useCallback } from 'react';

import {
  FetchMaterialsCacheKey,
  FetchMaterialsSort,
  FetchMaterialsSortTypes
} from '../../materialsTypes';

import { useMaterialsFiltersCache } from '../useMaterialsFiltersCache';

interface MaterialsSortDropdownOptions {
  materialsBaseCacheKey: FetchMaterialsCacheKey;
  sortMaterials: (nextSort: FetchMaterialsSort) => void;
}

function useMaterialsSortDropdown({
  materialsBaseCacheKey,
  sortMaterials
}: MaterialsSortDropdownOptions) {
  const { setCachedMaterialsSort } = useMaterialsFiltersCache({
    cacheKey: materialsBaseCacheKey
  });

  const handleSortByMostLiked = useCallback<() => void>(() => {
    setCachedMaterialsSort([
      FetchMaterialsSortTypes.USERS_CHOSEN_MATERIALS_COUNT_DESC
    ]);
    sortMaterials([FetchMaterialsSortTypes.USERS_CHOSEN_MATERIALS_COUNT_DESC]);
  }, [sortMaterials, setCachedMaterialsSort]);

  const handleSortByMostUsed = useCallback<() => void>(() => {
    setCachedMaterialsSort([
      FetchMaterialsSortTypes.SELECTED_MATERIALS_COUNT_DESC
    ]);
    sortMaterials([FetchMaterialsSortTypes.SELECTED_MATERIALS_COUNT_DESC]);
  }, [sortMaterials, setCachedMaterialsSort]);

  const handleSortByNewest = useCallback<() => void>(() => {
    setCachedMaterialsSort([FetchMaterialsSortTypes.CREATED_AT_DESC]);
    sortMaterials([FetchMaterialsSortTypes.CREATED_AT_DESC]);
  }, [sortMaterials, setCachedMaterialsSort]);

  return {
    handleSortByMostLiked,
    handleSortByMostUsed,
    handleSortByNewest
  };
}

export default useMaterialsSortDropdown;
