import React, { useCallback } from 'react';

import {
  FetchMaterialsFilters,
  ChangeMaterialsFiltersFunc
} from '../../../materialsTypes';

import { Translate } from '../../../../../helpers/Translate';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { formsFields, words } from '../../../../../locales/keys';

interface MaterialsFavoriteCheckboxFilterProps {
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

const name = 'favorite' as const;

function MaterialsFavoriteCheckboxFilter({
  materialsFilters,
  changeMaterialsFilters
}: MaterialsFavoriteCheckboxFilterProps) {
  const checked = materialsFilters?.[name];
  const handleClick = useCallback(() => {
    if (checked) {
      changeMaterialsFilters({}, [name]);
    } else {
      changeMaterialsFilters({ [name]: true });
    }
  }, [checked, changeMaterialsFilters]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.favorites} />
        </h6>
      </div>
      <Checkbox
        checkboxWrapperClassName="flex items-center"
        i18nLabel={words.showFavoriteOnly}
        checked={checked}
        onChange={handleClick}
      />
    </div>
  );
}

export default MaterialsFavoriteCheckboxFilter;
