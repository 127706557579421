import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { ID } from '../../../../../../../types';

import {
  MaterialsFiltersNavBasePath,
  ChangeMaterialsFiltersFunc
} from '../../../../../materialsTypes';

import { MaterialsIndexPageCategoriesNavCategory } from '../../MaterialsIndexPageCategoriesNav.types';

import { MaterialsIndexPageCategoriesNavItem } from '../MaterialsIndexPageCategoriesNavItem';
import { MaterialsIndexPageCategoriesNavAmountButton } from '../MaterialsIndexPageCategoriesNavAmountButton';

interface MaterialsIndexPageCategoriesNavListProps {
  materialCategories: MaterialsIndexPageCategoriesNavCategory[];
  parentCategoryId?: ID;
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

const defaultListCategoriesSize = 8;

function MaterialsIndexPageCategoriesNavList({
  materialCategories,
  parentCategoryId,
  filtersNavBasePath,
  changeMaterialsFilters
}: MaterialsIndexPageCategoriesNavListProps) {
  const listCategories = useMemo<MaterialsIndexPageCategoriesNavCategory[]>(
    () =>
      sortBy(
        filter(materialCategories, (category) =>
          parentCategoryId
            ? category.parent?.id === parentCategoryId
            : !category.parent
        ),
        'localizedName'
      ),
    [materialCategories, parentCategoryId]
  );

  const [showAll, setShowAll] = useState(false);

  if (size(listCategories) <= defaultListCategoriesSize) {
    return (
      <>
        {listCategories.map((category) => (
          <MaterialsIndexPageCategoriesNavItem
            key={category.id}
            category={category}
            categories={materialCategories}
            filtersNavBasePath={filtersNavBasePath}
            changeMaterialsFilters={changeMaterialsFilters}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll
    ? listCategories
    : take(listCategories, defaultListCategoriesSize);

  return (
    <>
      {showedCategories.map((category) => (
        <MaterialsIndexPageCategoriesNavItem
          key={category.id}
          category={category}
          categories={materialCategories}
          filtersNavBasePath={filtersNavBasePath}
          changeMaterialsFilters={changeMaterialsFilters}
        />
      ))}
      <MaterialsIndexPageCategoriesNavAmountButton
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default MaterialsIndexPageCategoriesNavList;
