import { useCallback, useMemo } from 'react';
import size from 'lodash/size';
import some from 'lodash/some';
import compact from 'lodash/compact';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKeys
} from '../../../../../../materialsSets/materialsSetsTypes';

import {
  OnSelectedMaterialsSidebarOpenAction,
  OnSelectedMaterialsSidebarCloseAction
} from '../../../../../materialsTypes';

import { TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY } from '../../../../../../materialsSets/queries/toggleMaterialInMaterialsSet.query';

import { useToggleMaterialInMaterialsSet } from '../../../../../../materialsSets/hooks/useToggleMaterialInMaterialsSet';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import {
  MaterialsListItemLightboxSelectButtonMaterial,
  MaterialsListItemLightboxSelectButtonMaterialsSet
} from './MaterialsListItemLightboxSelectButton.types';
import { MaterialsSetCache } from '../../../../../../materialsSets/MaterialsSetCache';

interface UseMaterialsListItemLightboxSelectButtonOptions {
  material: MaterialsListItemLightboxSelectButtonMaterial;
  materialsSet: MaterialsListItemLightboxSelectButtonMaterialsSet | null;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onSelectedMaterialsSidebarOpen: OnSelectedMaterialsSidebarOpenAction;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<MaterialsListItemLightboxSelectButtonMaterialsSet>;
}

function useMaterialsListItemLightboxSelectButton({
  material,
  materialsSet,
  materialsSetCacheKeys,
  onSelectedMaterialsSidebarOpen,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache
}: UseMaterialsListItemLightboxSelectButtonOptions) {
  const isSelected = useMemo<boolean>(() => {
    if (!materialsSet || !materialsSet.selectedMaterials) {
      return false;
    }
    return some(
      materialsSet.selectedMaterials,
      (selectedMaterial) => selectedMaterial?.material.uuid === material.uuid
    );
  }, [material, materialsSet]);

  const {
    toggleMaterialInMaterialsSetErrorMessage,
    toggleMaterialInMaterialsSet
  } = useToggleMaterialInMaterialsSet({
    query: TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY,
    cacheKeys: compact([
      MaterialsSetCache.showCacheKey(),
      ...(materialsSetCacheKeys || [])
    ]),
    material,
    updateMaterialsSetCache
  });

  const selectedMaterialsAmount = size(materialsSet?.selectedMaterials);

  const handleAfterToggle = useCallback(() => {
    if (selectedMaterialsAmount === 0 && !isSelected) {
      onSelectedMaterialsSidebarOpen();
    }
    if (selectedMaterialsAmount === 1 && isSelected) {
      onSelectedMaterialsSidebarClose();
    }
  }, [
    isSelected,
    selectedMaterialsAmount,
    onSelectedMaterialsSidebarOpen,
    onSelectedMaterialsSidebarClose
  ]);

  const materialsSetUuid = materialsSet?.uuid;
  const materialNanoId = material?.nanoId;

  const handleToggleSelected = useCallback(() => {
    if (!materialsSetUuid || !materialNanoId) {
      return;
    }

    toggleMaterialInMaterialsSet({
      uuid: materialsSetUuid,
      materialId: materialNanoId
    });

    handleAfterToggle();
  }, [
    materialsSetUuid,
    materialNanoId,
    toggleMaterialInMaterialsSet,
    handleAfterToggle
  ]);

  useShowToastOnErrorChange({
    error: toggleMaterialInMaterialsSetErrorMessage
  });

  return {
    isSelected,
    handleToggleSelected
  };
}

export default useMaterialsListItemLightboxSelectButton;
