import React, { Fragment, memo } from 'react';

import {
  FetchMaterialsFilters,
  MaterialsFiltersNavBasePath,
  MaterialsCustomExitNavPath,
  MaterialsI18nCustomBaseTitle,
  ChangeMaterialsFiltersFunc,
  FetchMaterialsTotalCount
} from '../../materialsTypes';
import { IconsEnum } from '../../../../assets/icons/types';

import useMaterialsIndexPageBreadcrumbs from './useMaterialsIndexPageBreadcrumbs';

import { MaterialsIndexPageBreadcrumbsItem } from './components/MaterialsIndexPageBreadcrumbsItem';

import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { MaterialPath } from '../../MaterialPath';
import { StockPath } from '../../../stock/StockPath';

import { getChangeMaterialsFiltersCategoriesData } from '../../utils/getChangeMaterialsFiltersCategoriesData';

import { MaterialsPermissions } from '../../materialsConstants';
import { materialsKeys } from '../../../../locales/keys';

interface MaterialsIndexPageBreadcrumbsProps {
  filtersNavBasePath?: MaterialsFiltersNavBasePath;
  customExitNavPath?: MaterialsCustomExitNavPath;
  i18nCustomBaseTitle?: MaterialsI18nCustomBaseTitle;
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
  materialsTotalCount: FetchMaterialsTotalCount;
  withoutStockLink?: boolean;
}

function MaterialsIndexPageBreadcrumbs({
  filtersNavBasePath,
  customExitNavPath,
  i18nCustomBaseTitle,
  materialsFilters,
  changeMaterialsFilters,
  materialsTotalCount,
  withoutStockLink
}: MaterialsIndexPageBreadcrumbsProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    materialCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useMaterialsIndexPageBreadcrumbs({
    materialsFilters,
    changeMaterialsFilters
  });

  return (
    <div className="flex-1 truncate flex pl-3 pr-1 h-full items-center text-base xl:text-lg text-gray-500 space-x-1.5">
      {!withoutStockLink && (
        <Fragment>
          <NextPureLinkHelper
            href={customExitNavPath || StockPath.materials()}
            className="hover:underline"
            icon={IconsEnum.CUBE_OUTLINE}
            iconClassName="h-6 w-6"
          />
          <span>/</span>
        </Fragment>
      )}

      <MaterialsIndexPageBreadcrumbsItem
        href={filtersNavBasePath}
        active={!currentChildCategory && !currentParentCategory}
        i18nText={i18nCustomBaseTitle || materialsKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
        onChangeFilters={handleResetCategoriesFilters}
      />
      {currentParentCategory ? (
        <>
          {currentParentCategory.parent && (
            <>
              <span>/</span>
              <MaterialsIndexPageBreadcrumbsItem
                href={
                  filtersNavBasePath
                    ? MaterialPath.indexFiltersCustomBaseQuery(
                        filtersNavBasePath,
                        getChangeMaterialsFiltersCategoriesData({
                          materialCategory: currentParentCategory.parent,
                          categories: materialCategories
                        }).changeFilters
                      )
                    : '#'
                }
                active={false}
                text={currentParentCategory.parent.localizedName}
                filtersNavBasePath={filtersNavBasePath}
                onChangeFilters={handleParentCategoryChangeFilters}
              />
            </>
          )}

          <span>/</span>
          <MaterialsIndexPageBreadcrumbsItem
            href={
              filtersNavBasePath
                ? MaterialPath.indexFiltersCustomBaseQuery(
                    filtersNavBasePath,
                    getChangeMaterialsFiltersCategoriesData({
                      materialCategory: currentParentCategory,
                      categories: materialCategories
                    }).changeFilters
                  )
                : '#'
            }
            active={!currentChildCategory}
            text={currentParentCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
            onChangeFilters={handleParentCategoryChangeFilters}
          />
        </>
      ) : null}

      {currentChildCategory ? (
        <>
          <span>/</span>
          <MaterialsIndexPageBreadcrumbsItem
            active
            text={currentChildCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
          />
        </>
      ) : null}

      {materialsTotalCount ? (
        <CheckPermissions
          action={MaterialsPermissions.READ_MATERIALS_TOTAL_COUNT}
        >
          <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
            {materialsTotalCount}
          </span>
        </CheckPermissions>
      ) : null}
    </div>
  );
}

export default memo<MaterialsIndexPageBreadcrumbsProps>(
  MaterialsIndexPageBreadcrumbs
);
