import React from 'react';

import { TextWithTooltip } from '../../../../../../../helpers/TextWithTooltip';
import { Translate } from '../../../../../../../helpers/Translate';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { MaterialsListItemLightboxTitleMaterial } from './MaterialsListItemLightboxTitle.types';

import { formsFields } from '../../../../../../../locales/keys';

interface MaterialsListItemLightboxTitleProps {
  material: MaterialsListItemLightboxTitleMaterial;
}

function MaterialsListItemLightboxTitle({
  material
}: MaterialsListItemLightboxTitleProps) {
  return (
    <div className="flex-1 overflow-hidden p-1.5 text-left">
      <div className="text-sm">
        <div className="text-white truncate">
          {material.blocked ? (
            <span className="bg-red-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id={formsFields.blocked} />
            </span>
          ) : null}
          <TextWithTooltip
            text={material.name}
            className="font-medium"
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="text-gray-300 truncate">
          {material.nda ? (
            <span className="bg-yellow-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id={formsFields.nda} />
            </span>
          ) : null}
          {material.brand ? (
            <TextWithTooltip
              text={material.brand.name}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MaterialsListItemLightboxTitle;
