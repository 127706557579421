import React from 'react';

import {
  FetchMaterialsFilters,
  MaterialClientID,
  ChangeMaterialsFiltersFunc
} from '../../../materialsTypes';

import { useMaterialsBrandsFilter } from './hooks/useMaterialsBrandsFilter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CheckboxAlphabeticalGroupFilter } from '../../../../../helpers/filters/CheckboxAlphabeticalGroupFilter';
import { Translate } from '../../../../../helpers/Translate';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { categoriesKeys, words } from '../../../../../locales/keys';

interface MaterialsBrandsFilterProps {
  clientIds?: MaterialClientID[];
  materialsFilters: FetchMaterialsFilters;
  changeMaterialsFilters: ChangeMaterialsFiltersFunc;
}

const name = 'brandId' as const;

function MaterialsBrandsFilter({
  clientIds,
  materialsFilters,
  changeMaterialsFilters
}: MaterialsBrandsFilterProps) {
  const filterValue = materialsFilters?.[name]?.in;

  const {
    materialBrandsFilterSelectedData,
    materialsBrandsFilterData,
    materialBrandsFilterErrorMessage,
    materialsBrandsFilterSelectedErrorMessage,
    materialBrandsFilterFetched,
    materialBrandsFilterIsPlaceholderData,
    materialBrandsFilterFetchingNextPage,
    hasNextMaterialBrandsFilterPage,
    materialBrandsFilterSearchValue,
    loadMoreMaterialBrandsFilter,
    handleMaterialBrandsFilterSearch,
    handleChangeMaterialsBrandsFilter
  } = useMaterialsBrandsFilter({
    clientIds,
    selectedIds: filterValue,
    name,
    changeMaterialsFilters
  });

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.brands.plural} />
        </h6>
      </div>
      <AlertMessage
        message={
          materialBrandsFilterErrorMessage ||
          materialsBrandsFilterSelectedErrorMessage
        }
      />

      <CheckboxAlphabeticalGroupFilter
        name={name}
        value={filterValue as string[]}
        sortedItems={materialsBrandsFilterData}
        selectedItems={materialBrandsFilterSelectedData}
        itemsFetched={
          materialBrandsFilterIsPlaceholderData || materialBrandsFilterFetched
        }
        i18nSearchPlaceholder={categoriesKeys.brands.search}
        searchValue={materialBrandsFilterSearchValue}
        fetchMoreButton={
          <FetchMoreInfiniteButtonHelper
            errorMessage={materialBrandsFilterErrorMessage}
            isLoading={materialBrandsFilterFetchingNextPage}
            hasNextPage={hasNextMaterialBrandsFilterPage}
            i18nText={words.showMore}
            wrapperClassName="relative p-2 text-center"
            className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
            onFetchMore={loadMoreMaterialBrandsFilter}
          />
        }
        onSearch={handleMaterialBrandsFilterSearch}
        onChange={handleChangeMaterialsBrandsFilter}
      />
    </div>
  );
}

export default MaterialsBrandsFilter;
